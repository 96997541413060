<template>
    <el-input
        v-model="inputValue"
        :size="size"
        :placeholder="placeholder"
        @keydown.native.tab="onBlur"
        @keyup.native.enter="onBlur"
        @blur="checkEmptyValue"
        :disabled="disabled"
        @focus="$emit('focus', $event)"
    />
</template>
<script>
import moment from "moment";

export default {
    props: {
        placeholder: {
            type: String,
            default: "Datum",
        },

        preSelectedValue: {
            type: String,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        size: {
            type: String,
            default: "small",
        },
    },

    watch: {
        preSelectedValue() {
            this.inputValue = this.preSelectedValue;
        },
    },

    data() {
        return {
            inputValue: this.preSelectedValue,
        };
    },

    methods: {
        onBlur() {
            if (this.inputValue != null) {
                let now = moment().format("YYYYMMDD");
                let input = this.inputValue.replace(/[-. /]/g, "").padStart(2, "0");
                input = now.slice(0, -input.length) + input;
                this.inputValue = moment(input).isValid() ? moment(input).format("YYYY-MM-DD") : null;
            }
            this.$emit("change", this.inputValue);
        },

        checkEmptyValue() {
            this.$emit("change", this.inputValue);
        },

        reset() {
            this.inputValue = "";
        },
    },
};
</script>
